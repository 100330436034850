import {MenuItem} from 'primeng/api';
import {MenuItemCommandEvent} from 'primeng/api/menuitem';
import {SidenavItemPermissionCriteria, SidenavItemPermissions} from './sidenav-item.types';

type SidenavItemConfig = {
	label: string;
	icon: string;
} & Partial<{
	styleClass?: string;
	allowedPermissions:
		| SidenavItemPermissionCriteria[]
		| {
				allowed: {
					required: SidenavItemPermissionCriteria[];
					optional?: SidenavItemPermissionCriteria[];
				};
				denied: SidenavItemPermissionCriteria[];
		  };
	deniedPermissions: SidenavItemPermissionCriteria[];
	optionalPermissions: SidenavItemPermissionCriteria[];
	items?: (SidenavItemModel | MenuItem)[];
	route?: string;
	command?: (event: MenuItemCommandEvent) => void;
	isActive?: (activeRoute: string) => boolean;
	counter?: number;
}>;

export class SidenavItemModel implements MenuItem {
	label: string;
	icon: string;
	styleClass: string;
	permissions: SidenavItemPermissions;
	routerLink?: string;
	counter?: number = null;
	items?: (SidenavItemModel | MenuItem)[];
	command?: (event: MenuItemCommandEvent) => void;

	isActive: (activeRoute: string) => boolean = (activeRoute: string) => activeRoute.includes(this.routerLink || '');

	constructor(config: SidenavItemConfig) {
		this.label = config.label;
		this.icon = config.icon ? config.icon + 'sub-item-icon fa-lg border border-solid aspect-square rounded-md border-gray-300 p-1 m-0' : '';

		this.styleClass = config?.styleClass ? `'cursor-pointer font-dc ${config.styleClass}` : 'cursor-pointer font-dc';
		if (this.label === 'חדש' || this.label === 'New') this.styleClass = 'cursor-pointer font-dc bg-white text-gray-100 font-bold rounded-md';

		config.allowedPermissions = config.allowedPermissions || [];
		config.deniedPermissions = config.deniedPermissions || [];
		config.optionalPermissions = config.optionalPermissions || [];
		this.permissions = Array.isArray(config.allowedPermissions)
			? {
					allowed: {
						required: config.allowedPermissions,
						optional: config.optionalPermissions,
					},
					denied: config.deniedPermissions,
				}
			: config.allowedPermissions;

		this.routerLink = config?.route ? config.route : undefined;
		this.counter = config?.counter ?? null;
		this.items = config?.items?.map(item => {
			item.parent = this;
			return item;
		});
		this.command = config?.command ?? null;
		this.isActive = config?.isActive ?? (activeRoute => activeRoute.includes(this.routerLink || ''));
	}

	/* ----------------------------------------------  Parent Node ---------------------------------------------- */
	private _parent: SidenavItemModel | null = null;
	public get parent(): SidenavItemModel | null {
		return this._parent;
	}

	public set parent(parent: SidenavItemModel | null) {
		if (parent) this.styleClass += 'sub-item';
		else this.styleClass = this.styleClass.replace('sub-item', '');
		this._parent = parent;
	}

	/* ------------------------------------------------  Icon ------------------------------------------------ */
}
